import { useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { BannerSlim, Box } from "gestalt";

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  //TODO: remove this in prod
  console.log({ error });
  return (
    <Box padding={5}>
      <BannerSlim
        message="Something unexpected happened!"
        type="error"
        iconAccessibilityLabel="error"
      />
    </Box>
  );
};
